<template>
  <AppLoading :comment="codeMessage"/>
</template>

<script>
import * as authAPI from '../plugins/apiHelper_auth'

export default {
  data() {
    return {
      code: '',
      codeMessage: '',
    }
  },
  async mounted() {
    this.code = this.$route.query.code;
    await this.loginCode();
  },
  methods: {
    async loginCode() {
      try {
        if( this.code.length < 80 ){
          throw new Error();
        }else{
          this.codeMessage = '게임 접속 중 입니다.';
        }

        const data = await authAPI.loginByCode({code: this.code});

        // this.$store.state.loginToken = data.loginToken;
        // sessionStorage.setItem(`loginToken`, data.loginToken);
        // this.$store.commit('SET_LOGIN_TOKEN', data.loginToken);
        await this.$store.dispatch('auth_login', data).then(async () => {
          await this.$router.replace({name: 'main'});
        });

      } catch (e) {
        if (e == -9999) {
          this.codeMessage = '점검 중 입니다.';
          alert('점검 중 입니다');
        } else {
          this.codeMessage = '코드를 다시 한 번 확인해 주세요.';
          alert(this.codeMessage);
        }
        console.log(e)
      }
    }
  }
}
</script>